/* global $ */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { showToast } from "../../utils/toastUtils";
import { useMediaQuery } from '@mui/material';
import LoginModal from "../../components/auth/LoginModal";
import { InfoIcon, HtmlTooltip } from '../../utils/tooltipUtils';
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { questionsByRegistryType } from "../../utils/questionsUtils";
import { getRegistryCredentials, clearRegistryCredentials } from '../../utils/registryUtils';
import api from "../../services/api";
import Cookies from 'js-cookie';

const OnboardingProcessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isPortrait = useMediaQuery('(max-width:575px)');
  const [loading, setLoading] = useState(false);
  const [storedUserName, setStoredUserName] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [selectedMyRole, setSelectedMyRole] = useState("");
  const [selectedPartnerRole, setSelectedPartnerRole] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [year, setYear] = useState("");
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('India');
  const [phoneNo, setPhoneNo] = useState('');
  const [showVisitorPassword, setShowVisitorPassword] = useState(false);
  const [isVisitorPasswordValid, setIsVisitorPasswordValid] = useState(true);
  const [allowFoundOnQuickRegistry, setAllowFoundOnQuickRegistry] = useState(true);
  const [visitorPasswordCheckbox, setVisitorPasswordCheckbox] = useState(true);
  const [visitorPassword, setVisitorPassword] = useState('');
  const [activeButton, setActiveButton] = useState('extension');
  const query = new URLSearchParams(location.search);
  const startStep = parseInt(query.get("processStep")) || 1;
  const [currentStep, setCurrentStep] = useState(startStep);
  const [registryTypeId, setRegistryTypeId] = useState(() => {
    return Cookies.get("registryTypeId") || localStorage.getItem("registryTypeId") || "1";
  });
  const { accessToken, userId, registryId, userName, fromMobileApp, fromiOSDevice } = getRegistryCredentials();
  
  useEffect(() => {
    let currentStepLocal = currentStep;
    var updateProgressBar;

    $('#multi-step-form').find('.step').slice(1).hide();

    $(".next-step").click(function () {
      if (currentStepLocal === 6 && fromMobileApp) {
        return;
      }

      if (currentStepLocal < 7) {
        $(".step-" + currentStepLocal).addClass("animate__animated animate__fadeOutLeft");
        currentStepLocal++;
        setTimeout(function () {
          $(".step").removeClass("animate__animated animate__fadeOutLeft").hide();
          $(".step-" + currentStepLocal).show().addClass("animate__animated animate__fadeInRight");
          setCurrentStep(currentStepLocal);
          updateProgressBar();
        }, 500);
      }
    });

    $(".prev-step").click(function () {
      if (currentStepLocal > 1) {
        $(".step-" + currentStepLocal).addClass("animate__animated animate__fadeOutRight");
        currentStepLocal--;
        setTimeout(function () {
          $(".step").removeClass("animate__animated animate__fadeOutRight").hide();
          $(".step-" + currentStepLocal).show().addClass("animate__animated animate__fadeInLeft");
          setCurrentStep(currentStepLocal);
          updateProgressBar();
        }, 500);
      }
    });

    updateProgressBar = function () {
      var progressPercentage = ((currentStepLocal - 1) / 7) * 100;
      $(".progress-bar").css("width", progressPercentage + "%");
    }

    $(".step").hide();
    $(".step-" + currentStepLocal).show();

  }, [fromMobileApp, currentStep]);

  useEffect(() => {
    const handleLogOutClick = () => {
      document.querySelector("ul.overlay").classList.toggle("active-overlay");
    };

    const handleOutsideClick = (event) => {
      const overlay = document.querySelector("ul.overlay");
      const logOutButton = document.querySelector("button.log-out");
      if (overlay && !overlay.contains(event.target) && logOutButton && !logOutButton.contains(event.target)) {
        overlay.classList.remove("active-overlay");
      }
    };

    const logOutButton = document.querySelector("button.log-out");
    if (logOutButton) {
      logOutButton.addEventListener("click", handleLogOutClick);
    }

    document.addEventListener("click", handleOutsideClick);

    return () => {
      if (logOutButton) {
        logOutButton.removeEventListener("click", handleLogOutClick);
      }
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (userName) {
      setStoredUserName(userName);
    }
    // eslint-disable-next-line
  }, []);

  const handleRegistrySelection = async (selectedTypeId, name) => {
    try {
      setRegistryTypeId(selectedTypeId);
      const registryResponse = await api.createRegistryId(accessToken, parseInt(userId), selectedTypeId, name);
      const registryId = registryResponse.registry_id;
      Cookies.set('registryId', registryId, { expires: 30, sameSite: 'None', secure: true });
      localStorage.setItem('registryId', registryId);
    } catch (error) {
      console.error("Error creating registry ID:", error.message);
      showToast('Failed to select registry. Please try again', "error");
    }
  };

  const handleFirstNameChange = (event) => {
    setPartnerName(`${event.target.value} ${partnerName.split(" ")[1] || ''}`);
  };

  const handleLastNameChange = (event) => {
    setPartnerName(`${partnerName.split(" ")[0] || ''} ${event.target.value}`);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleDayChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue === '' || (/^\d+$/.test(inputValue) && inputValue >= 1 && inputValue <= 31)) {
      setDay(inputValue);
    }
  };
  
  const handleYearChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue === '' || (/^\d+$/.test(inputValue) && inputValue.length <= 4)) {
      setYear(inputValue);
    }
  };  

  const validateArrivalDate = () => {
    return month !== '' && day !== '' && year !== '' && year.length === 4;
  };

  const validateForm = () => {
    return (
      firstName.trim() !== '' &&
      lastName.trim() !== '' &&
      address1.trim() !== '' &&
      city.trim() !== '' &&
      zipCode.trim().length === 6 &&
      state.trim() !== '' &&
      phoneNo.trim().length === 10
    );
  };

  const validatePassword = () => {
    return !visitorPasswordCheckbox || visitorPassword.trim() !== '';
  };
  
  const handleSaveDetailsAnswers = async () => {
    try {
      const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      const registryQuestionMapping = {
        1: {
          myRole: "1",
          partnerName: "2",
          arrivalDate: "3"
        },
        2: {
          myRole: "4",
          partnerName: "5",
          eventDate: "6"
        }
      };
    
      const questionIds = registryQuestionMapping[registryTypeId];
      const userDetails = [
        {
          "registry_question_id": questionIds.myRole,
          "question_answer": selectedMyRole,
          "remarks": "null"
        },
        {
          "registry_question_id": questionIds.partnerName, 
          "question_answer": partnerName,
          "remarks": selectedPartnerRole
        },
        {
          "registry_question_id": questionIds.arrivalDate || questionIds.eventDate,
          "question_answer": formattedDate,
          "remarks": "null"
        }
      ];
  
      await api.saveUserDetailsAnswers(accessToken, registryId, userDetails);
  
    } catch (error) {
      console.error("Error saving user details answers:", error.message);
      showToast('Failed to save previous answers', "error");
    }
  };

  const toggleVisitorPasswordVisibility = () => {
    setShowVisitorPassword(!showVisitorPassword);
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setVisitorPassword(password);

    if (password.trim() === '') {
      setIsVisitorPasswordValid(false);
      showToast('Please enter a visitor password');
    } else {
      setIsVisitorPasswordValid(true);
    }
  };

  const handleCompleteRegistry = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      
      const { description } = questionsByRegistryType[registryTypeId];
      const registryDetails = {
        user_id: parseInt(userId),
        registry_type_id: registryTypeId,
        description: description || null,
        public: allowFoundOnQuickRegistry,
        password_option: visitorPasswordCheckbox,
        visitor_password: visitorPassword || null,
        address: {
          first_name: firstName,
          last_name: lastName,
          address_1: address1,
          address_2: address2 || null,
          city: city,
          state: state,
          country: country,
          zip_code: zipCode,
          instructions: ""
        },
        mobile_no: phoneNo 
      };
  
      await api.completeRegistryDetails(accessToken, registryId, registryDetails);
      if(fromMobileApp){
        handleContinue();
      }
    } catch (error) {
      console.error("Error saving user other answers:", error.message);
      if (fromMobileApp) {
        showToast('Failed to create registry. Please try again', 'error');
      } else {
        showToast('Failed to save previous answers', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleButtonChange = (button) => {
    setActiveButton(button);
  };

  const handleContinue = () => {
    GoogleAuth.signOut();
    clearRegistryCredentials();
    navigate(fromMobileApp ? "/m-login" : "/");
    showToast('Registry created successfully. Please Login here', "success");
    if (!fromMobileApp) {
      setTimeout(() => {
        $('#Login').modal('show');
      }, 100);
    }
  };

  const handleLogout = async () => {
    try {
      await api.logout(accessToken);
      GoogleAuth.signOut();
      clearRegistryCredentials();
      navigate("/");
    } catch (error) {
      clearRegistryCredentials();
      navigate("/");
      console.error("Logout error:", error.message);
    }
  };

  return (
    <div className="wrapper animsition">
      <div className="progress" style={{ height: "7px" }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: "0%" }}
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>

      <div className="wrapper-inner">
        <main className="main">
          <article>
            <section className="onboarding-process text-center">
              <div className="container">
                <div className="row">
                  <div className="process-box">
                    <div className="col-md-12">
                    {fromiOSDevice && <div className="spacing-mid"></div>}
                      <div className="spacing-mid"></div>
                      <Link className="navbar-logo" to="#">
                        <img
                          src="assets/images/logo.png"
                          alt="logo"
                          loading="lazy"
                        />
                      </Link>
                      <div className="spacing-mid"></div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <form id="multi-step-form" noValidate>
                    <div className="step step-1">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="process-heading">
                            <h2>What would you like to create?</h2>
                            <div className="spacing-mid"></div>
                          </div>
                        </div>
                        <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="registry-box">
                                <button type="button" className="next-step" onClick={() => handleRegistrySelection(1, "My Baby Registry")}>
                                    <h4>Baby Registry</h4>
                                    <img src="assets/images/baby-registry.svg" alt="baby-registry" />
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="registry-box">
                                <button type="button" className="next-step" onClick={() => handleRegistrySelection(2, "My Wedding Registry")}>
                                    <h4>Wedding Registry</h4>
                                    <img src="assets/images/marriage-registry.svg" alt="marriage-registry" />
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="registry-box">
                                <button type="button" className="disabled-button" disabled>
                                    <h4>Gift list</h4>
                                    <img src="assets/images/gift.svg" alt="gift" />
                                </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="step step-2">
                      <div className="col-lg-12">
                        <div className="process-heading">
                          <h2>Hi, {storedUserName}. We’re happy you’re here!</h2>
                          <div className="spacing-mid"></div>
                        </div>
                        <p className="text-center mb-5 fs-3">
                          {questionsByRegistryType[registryTypeId].sequence1.question}
                        </p>
                        <div className="arrival-date align-left">
                          <div className="user-box row">
                            <div className="col-md-12">
                              <div className="user-box partner-name mb-4">
                                {questionsByRegistryType[registryTypeId].sequence1.options.map(
                                  (option) => (
                                    <Link
                                      key={option}
                                      className={
                                        selectedMyRole === option ? "active-role-btn" : ""
                                      }
                                      onClick={() => setSelectedMyRole(option)}
                                    >
                                      {option}
                                    </Link>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="onboarding-btn">
                          <button
                            type="button"
                            className="btn btn-primary main-btn next-step"
                            disabled={selectedMyRole === ""}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="step step-3">
                      <div className="col-lg-12">
                        <div className="process-heading">
                          <h2>{questionsByRegistryType[registryTypeId].sequence2.question}</h2>
                          <div className="spacing-mid"></div>
                        </div>
                        <div className="arrival-date align-left">
                        <p className="mb-3">This is an optional step. you can always add it later.</p>
                          <div className="user-box row">
                            <div className="col-md-6">
                              <div className="user-box">
                              <input
                                type="text"
                                name="First Name"
                                onChange={handleFirstNameChange}
                                autoComplete="offAuto"
                                required
                              />
                              <label>First Name</label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="user-box">
                              <input
                                type="text"
                                name="Last Name"
                                onChange={handleLastNameChange}
                                autoComplete="offAuto"
                                required
                              />
                              <label>Last Name</label>
                              </div>
                            </div>
                          </div>
                          <div className="user-box row">
                            <div className="col-md-12">
                              <div className="user-box partner-name mb-4">
                                {questionsByRegistryType[registryTypeId].sequence2.options.map(
                                  (option) => (
                                    <Link
                                      key={option}
                                      className={
                                        selectedPartnerRole === option ? "active-role-btn" : ""
                                      }
                                      onClick={() => setSelectedPartnerRole(option)}
                                    >
                                      {option}
                                    </Link>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="onboarding-btn">
                          <button
                            type="button"
                            className="btn btn-primary prev-step"
                          >
                            Previous
                          </button>&nbsp;
                          <button
                            type="button"
                            className="btn btn-primary main-btn next-step"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="step step-4">
                      <div className="col-lg-12">
                        <div className="process-heading">
                          <h2 className="mb-4">
                            {questionsByRegistryType[registryTypeId].sequence3.question}
                          </h2>
                          <h4>You can add it now and change it later</h4>
                          <div className="spacing-mid"></div>
                        </div>
                        <div className="arrival-date align-left mb-5">
                          <p className="mb-2">
                            {questionsByRegistryType[registryTypeId].sequence3.inputLabel}
                          </p>
                          <div className="user-box row">
                            <div className="col-md-4">
                              <div className="select-wrapper static-wrapper">
                              <select
                                name="Month"
                                id="monthSelect"
                                className="form-control"
                                onChange={handleMonthChange}
                              >
                                  <option>Month</option>
                                  <option value="1">January</option>
                                  <option value="2">February</option>
                                  <option value="3">March</option>
                                  <option value="4">April</option>
                                  <option value="5">May</option>
                                  <option value="6">June</option>
                                  <option value="7">July</option>
                                  <option value="8">August</option>
                                  <option value="9">September</option>
                                  <option value="10">October</option>
                                  <option value="11">November</option>
                                  <option value="12">December</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <input
                                type="number"
                                name="Day"
                                id="dayInput"
                                className="form-control"
                                placeholder="Day"
                                value={day}
                                onChange={handleDayChange}
                                autoComplete="off"
                                min="1"
                                max="31"
                              />
                            </div>
                            <div className="col-md-4">
                              <input
                                type="number"
                                name="Year"
                                id="yearInput"
                                className="form-control"
                                placeholder="Year"
                                value={year}
                                onChange={handleYearChange}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="onboarding-btn">
                          <button
                            type="button"
                            className="btn btn-primary prev-step"
                          >
                            Previous
                          </button>&nbsp;
                          <button
                            type="button"
                            className="btn btn-primary main-btn next-step"
                            onClick={handleSaveDetailsAnswers}
                            disabled={!validateArrivalDate()}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="step step-5">
                      <div className="col-lg-12">
                        <div className="process-heading">
                          <h2>Where would you like your gifts sent?</h2>
                          <div className="spacing-mid"></div>
                        </div>
                        <div className="arrival-date align-left">
                          <p className="mb-3">
                            All fields are required unless otherwise specified.
                          </p>
                          <div className="user-box row">
                            <div className="col-md-6">
                              <div className="user-box">
                                <input
                                  type="text"
                                  name="First Name"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  autoComplete="offAuto"
                                  required
                                />
                                <label>First Name</label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="user-box">
                                <input
                                  type="text"
                                  name="Last Name"
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                  autoComplete="offAuto"
                                  required
                                />
                                <label>Last Name</label>
                              </div>
                            </div>
                          </div>
                          <div className="user-box row">
                            <div className="col-md-12">
                              <div className="user-box">
                                <input
                                  type="text"
                                  name="Address 1"
                                  value={address1}
                                  onChange={(e) => setAddress1(e.target.value)}
                                  autoComplete="offAuto"
                                  required
                                />
                                <label>Address 1</label>
                              </div>
                              <div className="user-box">
                                <input
                                  type="text"
                                  name="Address 2"
                                  value={address2}
                                  onChange={(e) => setAddress2(e.target.value)}
                                  autoComplete="offAuto"
                                  required
                                />
                                <label>Address 2 (optional)</label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="user-box">
                                <input
                                  type="text"
                                  name="City"
                                  value={city}
                                  onChange={(e) => setCity(e.target.value)}
                                  autoComplete="offAuto"
                                  required
                                />
                                <label>City</label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="user-box">
                                <input
                                  type="number"
                                  name="Zip Code"
                                  value={zipCode}
                                  onChange={(e) => {
                                    const enteredValue = e.target.value;
                                    if (enteredValue.length <= 6) {
                                      setZipCode(enteredValue);
                                    }
                                  }}
                                  autoComplete="offAuto"
                                  required
                                />
                                <label>Pin Code</label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="user-box">
                                <input
                                  type="text"
                                  name="State / Province"
                                  value={state}
                                  onChange={(e) => setState(e.target.value)}
                                  autoComplete="offAuto"
                                  required
                                />
                                <label>State / Province</label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="user-box">
                                <div className="select-wrapper address-info">
                                  <select
                                    name="Country"
                                    id="countrySelect"
                                    className="form-control"
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                  >
                                    <option value="India">India</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="user-box">
                                <input
                                  type="tel"
                                  name="Phone No."
                                  value={phoneNo}
                                  onChange={(e) => {
                                    const enteredValue = e.target.value;
                                    if (enteredValue.length <= 10) {
                                      setPhoneNo(enteredValue);
                                    }
                                  }}
                                  autoComplete="offAuto"
                                  required
                                />
                                <label>Phone No.</label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="onboarding-btn">
                          <button
                            type="button"
                            className="btn btn-primary prev-step"
                          >
                            Previous
                          </button>&nbsp;
                          <button
                          type="button"
                          className="btn btn-primary main-btn next-step"
                          disabled={!validateForm()}
                        >
                          Next
                        </button>
                        </div>
                      </div>
                    </div>
                    <div className="step step-6">
                      <div className="col-lg-12">
                        <div className="process-heading">
                          <h2>Additional settings</h2>
                          <div className="spacing-small"></div>
                        </div>
                        <div className="arrival-date additional-setting">
                          <div className="user-box mb-4">
                            <div className="checkbox checkbox-container">
                              <input type="checkbox" checked={allowFoundOnQuickRegistry}
                                onChange={() => setAllowFoundOnQuickRegistry(!allowFoundOnQuickRegistry)}
                              />&nbsp;
                              <label className="checkbox-text"> Allow my Baby Registry to be found on QuickRegistry.in</label>&nbsp;
                              <HtmlTooltip
                                title={
                                  <>
                                    <span>Unchecking this option will make your Baby Registry private. You can always change this setting later.</span>
                                  </>
                                }
                                placement={isPortrait ? "bottom" : "right"}
                                interactive
                              >
                                <InfoIcon src="assets/images/icon/info-icon.svg" alt="info-icon" className="info-icon" />
                              </HtmlTooltip>
                            </div>
                            <div className="checkbox">
                              <input type="checkbox" disabled />
                              <label className="checkbox-text custom-disabled">&nbsp; Hide purchased gifts</label>&nbsp;
                              <HtmlTooltip
                                title={
                                  <>
                                    <span>Hide purchased gifts from your gift list.</span>
                                  </>
                                }
                                placement={isPortrait ? "bottom" : "right"}
                                interactive
                              >
                                <InfoIcon src="assets/images/icon/info-icon.svg" alt="info-icon" className="info-icon" />
                              </HtmlTooltip>
                            </div>
                            <div className="checkbox">
                              <input type="checkbox" checked={visitorPasswordCheckbox}
                                 onChange={() => {
                                  setVisitorPasswordCheckbox(!visitorPasswordCheckbox);
                                  setIsVisitorPasswordValid(true);
                                }}
                              />
                              <label className="checkbox-text">&nbsp; Enable a Visitor Password</label>&nbsp;
                              <HtmlTooltip
                                title={
                                  <>
                                    <span>If you disable visitor password, your shipping address will be visible to visitors on your registry directly.</span>
                                  </>
                                }
                                placement={isPortrait ? "bottom" : "right"}
                                interactive
                              >
                                <InfoIcon src="assets/images/icon/info-icon.svg" alt="info-icon" className="info-icon" />
                              </HtmlTooltip>
                            </div>
                            {visitorPasswordCheckbox && (
                              <div className="user-box mb-3">
                                <input
                                  name="visitor-password"
                                  type={showVisitorPassword ? "text" : "password"}
                                  value={visitorPassword}
                                  placeholder="Password"
                                  maxLength={20}
                                  className={`form-control ${isVisitorPasswordValid ? '' : 'is-invalid'}`}
                                  onKeyPress={(e) => e.key === 'Enter' && handleCompleteRegistry(e)}
                                  onChange={handlePasswordChange}
                                  required
                                />
                                <p className="password-char-visitor">
                                  Maximum 20 characters
                                </p>
                                <Link
                                  className="password-toggle-icon position-absolute end-0 bottom-50 me-3"
                                  onClick={toggleVisitorPasswordVisibility}
                                >
                                  {showVisitorPassword ? (
                                    <i className="las la-eye-slash fs-4"></i>
                                  ) : (
                                    <i className="las la-eye fs-4"></i>
                                  )}
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="onboarding-btn">
                          <button
                            type="button"
                            className="btn btn-primary prev-step" 
                          >
                            Previous
                          </button>&nbsp;
                          <button
                            type="button"
                            className="btn btn-primary main-btn next-step"
                            onClick={handleCompleteRegistry}
                            disabled={!validatePassword() || loading}
                          >
                            {loading && fromMobileApp ? (
                              <>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp;Processing
                              </>
                            ) : (
                              fromMobileApp ? "Continue" : "Next"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="step step-7">
                      <div className="col-lg-12">
                        <div className="process-heading mb-5">
                          <h2>Last thing…</h2>
                          <div className="spacing-small last-thing-desc">
                            <p>The “Quick Registry” Button is what makes it possible to add items from any store in the world to your gift list on QuickRegistry.in</p>
                          </div>
                        </div>
                        <div className="last-steps-container">
                          <div className="last-steps-info-container">
                            <div className="how-it-works-container">
                              <p className="last-step-heading">How it works</p>
                            </div>

                            <div className="onboarding-btn point-btn my-1">
                              <button
                                type="button"
                                className={`btn btn-primary ${activeButton === 'extension' ? 'active-point-btn' : ''}`}
                                onClick={() => handleButtonChange('extension')}
                              >
                                Extension
                              </button>
                              <span className="or-text">or</span>
                              <button
                                type="button"
                                className={`btn btn-primary ${activeButton === 'bookmark' ? 'active-point-btn' : ''}`}
                                onClick={() => handleButtonChange('bookmark')}
                              >
                                Bookmark
                              </button>
                            </div>
                          </div>

                          {activeButton === 'extension' && (
                            <>
                              <div className="last-steps-info-container">
                                <span className="mulitple-list-number">1</span>
                                <span className="last-steps-details-container">
                                  <span className="last-step-heading">
                                    <Link
                                      to="https://chromewebstore.google.com/detail/quick-registry/bpfmfjcokemkfaofkheknefimcijonke?hl=en&authuser=8"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="change-new-color"
                                    > Click here </Link>
                                    
                                    or to add and <Link to="https://support.google.com/chrome/a/answer/11190170?hl=en" target="_blank" className="change-new-color">pin it</Link> to your browser
                                  </span>
                                  <br />
                                  <span>Just like your other extensions, it takes just a few clicks.</span>
                                </span>
                              </div>
                              <div className="last-steps-info-container add-qr-container">
                                <div className="onboarding-btn add-chrome-btn">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => window.open("https://chromewebstore.google.com/detail/quick-registry/bpfmfjcokemkfaofkheknefimcijonke?hl=en&authuser=8", '_blank')}
                                  >
                                    Add to Chrome
                                  </button>
                                </div>
                              </div>
                              <div className="last-steps-info-container">
                                <span className="mulitple-list-number">2</span>
                                <span className="last-steps-details-container">
                                  <span className="last-step-heading">Find a gift you would like</span>
                                  <br />
                                  <span>Visit any retail website and go to the product page of the gift you’d like to add.</span>
                                </span>
                              </div>
                              <div className="last-steps-info-container">
                                <span className="mulitple-list-number">3</span>
                                <span className="last-steps-details-container">
                                  <span className="last-step-heading">Add in Seconds</span>
                                  <br />
                                  <span>Click on the button in your browser, confirm some details, add the gift.</span>
                                </span>
                              </div>
                            </>
                          )}

                          {activeButton === 'bookmark' && (
                            <>
                              <div className="last-steps-info-container">
                                <span className="mulitple-list-number">1</span>
                                <span className="last-steps-details-container">
                                  <span className="last-step-heading">Show bookmarks</span>
                                  <br />
                                  <span>If you don’t see your bookmarks bar, go to the top right menu and select <span className="less-bold">Bookmarks &gt; Show Bookmarks Bar</span>.</span>
                                </span>
                              </div>
                              <div className="last-steps-info-container">
                                <span className="mulitple-list-number">2</span>
                                <span className="last-steps-details-container">
                                  <span className="last-step-heading">Add the button to your bookmarks bar</span>
                                  <br />
                                  <span>Drag the below<span className="less-bold"> Add To QR List </span>button to your bookmarks bar.</span>
                                </span>
                              </div>
                              <div className="last-steps-info-container add-qr-container">
                                <div className="button">
                                  <a
                                    id="btnBookmark"
                                    className="button-qr-outline"
                                    // eslint-disable-next-line no-script-url
                                    href="javascript:(function(){var currentDomain=window.location.hostname;var blockedDomains=['www.flipkart.com','www.nykaafashion.com'];var currentUrl=encodeURIComponent(window.location.href);if(blockedDomains.includes(currentDomain)){window.location.href='https://quickregistry.in/script-blocked?url='+currentUrl;}else{var iframeUrl='https://quickregistry.in/add-gift-modal';var iframeSrc=iframeUrl+'?fromWeb=true&url='+currentUrl;if(!document.getElementById('AddToMr3Iframe')){var overlay=document.createElement('div');overlay.id='overlay';document.body.appendChild(overlay);overlay.innerHTML='<div id=\'backgroundOverlay\' style=\'position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: #ddd; opacity: 0.9; z-index: 2147483646;\'></div><iframe id=\'AddToMr3Iframe\' src=\''+iframeSrc+'\' style=\'display: none; position: fixed; height: 600px; width: 900px; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 2147483647; opacity: 1; clip: rect(26px, 791px, 570px, 91px);\'></iframe>';var iframe=document.getElementById('AddToMr3Iframe');iframe.addEventListener('load',function(){var pageHTMLContent=document.documentElement.outerHTML;iframe.contentWindow.postMessage({action:'sendHTML',pageHTMLContent:pageHTMLContent},'*');});setTimeout(function(){iframe.style.display='block';},6000);window.addEventListener('message',function(event){if(event.data.action==='closeIframe'){document.body.style.overflow='visible';var iframe=document.getElementById('AddToMr3Iframe');if(iframe){iframe.parentNode.removeChild(iframe);var overlay=document.getElementById('overlay');if(overlay){overlay.parentNode.removeChild(overlay);}}}});}}})();"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    🎁Add To QR List
                                  </a>
                                </div>
                              </div>
                              <div className="last-steps-info-container">
                                <span className="mulitple-list-number">3</span>
                                <span className="last-steps-details-container">
                                  <span className="last-step-heading">Find a gift you would like</span>
                                  <br />
                                  <span>Visit any retail website and go to the product page of the gift you’d like to add.</span>
                                </span>
                              </div>
                              <div className="last-steps-info-container">
                                <span className="mulitple-list-number">4</span>
                                <span className="last-steps-details-container">
                                  <span className="last-step-heading">Add in Seconds</span>
                                  <br />
                                  <span>Click on the button in your bookmarks bar, confirm some details, add the gift.</span>
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="onboarding-btn mt-3">
                          <button
                            type="button"
                            className="btn btn-primary prev-step" 
                          >
                            Previous
                          </button>&nbsp;
                          <button
                            type="button"
                            className="btn btn-primary main-btn next-step"
                            onClick={handleContinue}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className={`login-text ${fromiOSDevice && 'ios-area'}`}>
                      <p className="fs-6 mt-1"><b>WELCOME, {storedUserName.toUpperCase()}</b> 
                        <button type="button" className="log-out">
                          <i className="la la-angle-down"></i>
                        </button>
                        <ul className="overlay">
                          <li>
                            <Link to="#" onClick={handleLogout}>Log Out</Link>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
             <LoginModal/>
            </section>
          </article>
        </main>
      </div>
    </div>
  );
};

export default OnboardingProcessPage;
